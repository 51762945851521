<template>
  <div name="index">
    <div class="container" style="margin: 0 10px">
      <div class="detail">
        <div v-for="item in zhengceNewsDetail" :key="item.id">
          <div class="source" v-if="isSource == '0'">
            {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
            <router-link :to="{ path: '/' }">{{ $t('index.index') }}</router-link>
            &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
            <router-link :to="{ name: 'zclist', query: { type: item.type } }">{{ item.type }}</router-link>
          </div>
          <div class="source" v-if="isSource == '1'">
            {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
            <router-link :to="{ path: '/business_development_index' }">{{ $t('index.index') }}</router-link>
            &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
            {{ $t('index.policyDeatil') }}
          </div>
          <div class="source" v-if="isSource == '2'">
            {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
            <router-link :to="{ path: '/tourism_environment_index' }">{{ $t('index.index') }}</router-link>
            &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
            {{ $t('index.policyDeatil') }}
          </div>
          <div class="source" v-if="isSource == '3'">
            {{ $t('public.currentLocation') }}：&nbsp;&nbsp;
            <router-link :to="{ path: '/business_development_index' }">{{ $t('index.index') }}</router-link>
            &nbsp;&nbsp;&gt;&gt;&nbsp;&nbsp;
            {{ $t('index.superviseDetail') }}
          </div>
          <h3>{{ item.name }}</h3>
          <h4>{{ item.time }}</h4>
          <img v-for="(_item, index) in imgList" :key="_item"
            :src="_item" @click="previewImg(index)"
          />
        </div>
      </div>
      <div class="footer">
        <p>{{ $t('footer.producer') }}：{{ $t('footer.producerName') }}</p>
        <!-- <p>{{ $t('footer.contactAddress') }}：{{ $t('footer.contactAddressName') }}</p> -->
      </div>
    </div>
  </div>
</template>
<script>
import CryptoJS from "../utils/CryptoJS";
import BASE from "../utils/base";

import { ImagePreview } from "vant";

export default {
  name: "app",
  components: {},
  activated() {
    // when the Vue app is booted up, this is run automatically.
    let data = "DpHxKkMbPymRYCFi" + new Date().getTime();
    // let _data = CryptoJS.encrypt(data);
    // console.log(_data);
    let that = this;

    // 获取浏览量
    // this.axios
    //   .get(BASE.genUrl('zxCommViews/list', {type:'%E6%99%BA%E6%85%A7%E7%9B%91%E7%9D%A3'}))
    //   .then(res => {
    //     console.log('浏览量', res)
    //     let data = res.data;
    //     if(data.result) {
    //       that.todayView = data.result.today;
    //       that.totalView = data.result.total;
    //     }
    // });

    // 政策详情查询
    let id = this.$route.query.id;
    
    const { isSource='0' } = this.$route.query
    this.isSource = isSource

    this.axios
      .get(BASE.genUrl("zxSupervisePolicy/list", { id: id }))
      .then((res) => {
        console.log("政策查询", res);
        let data = res.data;
        if (data.result) {
          that.zhengceNewsDetail = data.result.records;
        }
      });
  },
  computed: {
    prev: function () {
      return this.pageNo - 1 <= 0 ? 1 : this.pageNo - 1;
    },
    next: function () {
      return this.result.length >= this.pageSize
        ? this.pageNo + 1
        : this.pageNo;
    },
    imgList() {
      return this.zhengceNewsDetail[0].description.split(",");
    },
  },
  data: () => ({
    result: [],
    active: 1,
    todayView: null,
    totalView: null,
    zhengceNewsDetail: [],
    isSource: true
  }),
  methods: {
    changeTab(nav) {
      console.log(nav, 1111);
      this.active = nav;
    },
    previewImg(startPosition) {
      const images = this.imgList;
      ImagePreview({ images, startPosition, closeable: true });
    },
  },
};
</script>
<style scoped>
.detail h3 {
  color: #fff;
  font-size: 16px;
}
.detail h4 {
  color: #fff;
  font-size: 14px;
}
.detail .source {
  text-align: left;
  color: #fff;
  padding: 0.1rem;
  font-size: 14px;
}
.detail .source a {
  color: #fff;
}
.detail img {
  width: 100%;
}
.item-content {
  padding: 0.5rem 0;
}
.item-inner {
  display: flex;
  flex-direction: row;
}
.item-title {
  font-size: 14px;
  width: 85%;
}
.item-after {
  width: 15%;
}
.tab {
  display: none;
}
.tab-active {
  display: block;
}
#tab3 .tab3-top,
#tab2 .tab2-top {
  display: flex;
  flex-direction: row;
}
#tab3 .tab3-middle,
#tab2 .tab2-middle {
  display: flex;
  flex-direction: row;
}
#tab3 .item,
#tab2 .item {
  width: 33%;
  display: flex;
  flex-direction: row;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
  margin-right: 0.1rem;
}
#tab2 .item .l {
  width: 50%;
  display: block;
}
#tab2 .item .r {
  border-radius: 0.5rem;
  font-size: 12px;
  background: #fff;
  display: block;
}
#tab2 .item .r h3 {
  margin: 0;
  padding: 0;
}
#tab2 .item .r div {
  padding: 0 0.2rem;
}
#tab2 .info-item .t {
  font-weight: bold;
  font-size: 14px;
}
#tab2 .info-div,
#tab3 .info-div {
  width: 50%;
  margin-right: 0.1rem;
  margin-top: 20px;
}
#tab2 .info-title,
#tab2 .info-item,
#tab3 .info-title,
#tab3 .info-item {
  text-align: center;
}
#tab2 .info-items,
#tab3 .info-items {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  border: 1px solid #999;
  border-top: 0;
  color: #999;
}
#tab2 .info-title,
#tab3 .info-title {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.3rem 0;
  font-size: 12px;
  color: #fff;
  background: #b51414;
}
#tab2 .item,
#tab3 .item {
  border-radius: 0.5rem;
  color: #fe9b4a;
  background: #b51414;
  text-align: center;
  padding: 0.5rem 0;
}
.footer {
  color: #fe9b4a;
  text-align: center;
  font-size: 12px;
}
.list {
  margin-top: 1rem;
  padding: 1rem 0.8rem;
  font-size: 14px;
}
.list ul:before {
  background: none;
}
.list select {
  width: 45%;
  font-size: 12px;
  padding: 0.5rem 0;
  margin-top: 1rem;
  margin-right: 0.1rem;
  border-radius: 0.5rem;
  border: 1px solid #b51414;
}
.news-bg {
  background: url("../assets/tab-bg.png") center top no-repeat;
  background-size: 60%;
  position: absolute;
  width: 100%;
  top: -5px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 0;
}
.news {
  background: #fff;
  padding-top: 0.5rem;
  position: relative;
  border-radius: 0.5rem;
  margin-top: 1.5rem;
}
ol,
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.payinfo {
  margin-top: 0.5rem;
  display: flex;
  border-radius: 0.5rem;
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}
.payinfo .payinfo-item {
  flex-grow: 1;
  display: flex;
  /* padding: .5rem; */
}
.payinfo .payinfo-item .payinfo-item-l {
  /* flex-grow: 1; */
  display: flex;
  width: 30%;
  justify-content: center; /* 水平居中*/
  align-items: center; /* 垂直居中*/
}
.payinfo .payinfo-item .payinfo-item-l img {
  width: 50%;
}
.payinfo .payinfo-item .payinfo-item-r {
  color: #fff;
  padding: 1rem 0;
  text-align: left;
  font-size: 14px;
  /* width: 66%;
  float: left; */
}
.payinfo .payinfo-item dd,
.payinfo .payinfo-item dt {
  text-align: left;
}
.container .nav-tabs {
  display: flex;
}
.container .nav-tabs a.tab-link-active {
  color: #b51414;
  background: #fff;
  /* background: url('../../static/choose-bg.png') 100% 100% no-repeat; */
}
.container .nav-tabs .tab1-link,
.container .nav-tabs .tab2-link {
  margin-right: 2px;
}
.container .nav-tabs a {
  /* width: 33%; */
  font-size: 14px;
  color: #fff;
  flex-grow: 1;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  height: 35px;
  line-height: 35px;
  font-weight: bold;
  /* background: #b51414; */
  background-image: linear-gradient(0deg, #c82214 51%, #ed872e 95%);
}
.container .news .list {
  text-align: left;
  padding: 0.8rem;
}
.container .tabs #tab1 input.sfz,
.container .news input.sfz {
  border: 1px solid #b51414;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}
.container #tab1 button {
  background: #b51414;
  border: 0;
  color: #fff;
  font-size: 1rem;
  padding: 0.6rem 0;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
}
.container #tab1 .title {
  display: block;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
}
.container .tabs .tab-active {
  background: #fff;
  padding: 0.5rem;
  border-radius: 0 0 0.5rem 0.5rem;
}
.banner {
  width: 100%;
  height: auto;
}
.page {
  background: #b51414;
}
.search {
  margin-top: 10px;
  padding: 10px;
  background: #f7f7f7;
  border-radius: 0.5rem 0.5rem;
  text-align: center;
}
.search span {
  font-size: 14px;
  display: block;
  text-align: center;
  color: #999;
  margin-bottom: 20px;
}
</style>
